import Bugsnag from "@bugsnag/browser"
import BugsnagPerformance from "@bugsnag/browser-performance"

class CustomLogger {
  debug() {
    console.debug("[bugsnag]", ...arguments) // eslint-disable-line no-console
  }

  info() {
    console.info("[bugsnag]", ...arguments) // eslint-disable-line no-console
  }

  warn() {
    if (
      !arguments[0].includes(
        "not sent due to releaseStage/enabledReleaseStages configuration",
      )
    ) {
      console.warn("[bugsnag]", ...arguments)
    }
  }

  error() {
    console.error("[bugsnag]", ...arguments)
  }
}

function start(api) {
  api.start({
    apiKey: ENV.BUGSNAG_API_KEY,
    appVersion: ENV.BUGSNAG_APP_VERSION,
    enabledReleaseStages: ENV.BUGSNAG_ENABLED_RELEASE_STAGES,
    releaseStage: ENV.BUGSNAG_RELEASE_STAGE,
    logger: new CustomLogger(),
  })
}

if (ENV.BUGSNAG_API_KEY) {
  try {
    start(Bugsnag)
  } catch (error) {
    console.error(`Could not start up bugsnag due to: ${error.message}`)
  }

  try {
    start(BugsnagPerformance)
  } catch (error) {
    console.error(
      `Could not start up bugsnag performance due to: ${error.message}`,
    )
  }
}
