import urlRegexSafe from "url-regex-safe"
import emailRegexSafe from "email-regex-safe"
import isEmail from "validator/es/lib/isEmail"
import isURL from "validator/es/lib/isURL"

export function extractUrls(text) {
  const urls = []

  const urlRegex = new RegExp(urlRegexSafe({ returnString: true }), "gdi")

  let match = null
  while ((match = urlRegex.exec(text))) {
    const url = match[0]
    if (isURL(url)) {
      const [start, end] = match.indices[0]
      urls.push({ start, end, url })
    }
  }

  return urls
}

export function extractEmails(text) {
  const emails = []

  const emailRegex = new RegExp(emailRegexSafe({ returnString: true }), "gdi")

  let match = null
  while ((match = emailRegex.exec(text))) {
    const email = match[0]

    if (isEmail(email)) {
      const [start, end] = match.indices[0]
      emails.push({ start, end, email })
    }
  }

  return emails
}
