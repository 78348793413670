import sanitizeHtml from "../lib/sanitize_html"
import { extractUrls, extractEmails } from "../lib/extract_links"

document.addEventListener("trix-before-paste", function (e) {
  const html = e.paste.html
  if (html) {
    e.paste.html = sanitizeHtml(html, {
      allowedTags: ENV.TRIX_ALLOWED_TAGS,
      allowedAttrs: ENV.TRIX_ALLOWED_ATTRS,
    })
  }
})

document.addEventListener("trix-file-accept", function (e) {
  e.preventDefault()
})

document.addEventListener("trix-blur", function (e) {
  const editor = e.target.editor
  let document = editor.getDocument()
  const text = document.toString()

  for (const { start, end, url } of extractUrls(text)) {
    document = document.addAttributeAtRange("href", url, [start, end])
  }

  for (const { start, end, email } of extractEmails(text)) {
    document = document.addAttributeAtRange("href", `mailto:${email}`, [
      start,
      end,
    ])
  }

  editor.loadDocument(document)
})
