import { Controller } from "@hotwired/stimulus"
import { useTransition } from "stimulus-use"

export default class SidebarController extends Controller {
  static targets = ["scrollableContainer"]

  static values = {
    name: String,
  }

  connect() {
    useTransition(this, {
      element: this.element,
      hiddenClass: "hidden",
      transitioned: !this.element.classList.contains("hidden"),
    })
  }

  toggle(event) {
    if (this.forCurrentSidebar(event)) {
      this.toggleTransition()
      if (this.hasScrollableContainerTarget) {
        const scrollToElementId = this.scrollToElementId(event)
        if (scrollToElementId && this.transitioned) {
          this.scrollToElement(scrollToElementId)
        } else {
          this.scrollableContainerTarget.scrollTo(0, 0)
        }
      }
    } else {
      this.leave()
    }
  }

  hide(event) {
    if (this.forAllSidebars(event) || this.forCurrentSidebar(event)) {
      this.leave()
      if (this.hasScrollableContainerTarget) {
        this.scrollableContainerTarget.scrollTo(0, 0)
      }
    }
  }

  private

  forAllSidebars({
    detail: { sidebarName: detailSidebarName } = {},
    params: { sidebarName: paramsSidebarName } = {},
  }) {
    return !detailSidebarName && !paramsSidebarName
  }

  forCurrentSidebar({
    detail: { sidebarName: detailSidebarName } = {},
    params: { sidebarName: paramsSidebarName } = {},
  }) {
    return (
      this.nameValue === detailSidebarName ||
      this.nameValue === paramsSidebarName
    )
  }

  scrollToElementId({
    detail: { scrollToElement: detailScrollToElement } = {},
    params: { scrollToElement: paramsScrollToElement } = {},
  }) {
    return detailScrollToElement || paramsScrollToElement
  }

  scrollToElement(elementId) {
    if (!elementId) return
    const element = document.querySelector(`#${elementId}`)
    if (!element) return
    element.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "center",
    })
    element.focus()
  }
}
